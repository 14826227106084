/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { jsx, Spinner } from 'theme-ui'

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    zIndex: 1,
  },
  modalWrapper: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'black',
    overflowX: 'hidden',
    overflowY: 'hidden',
    p: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1,
  },
}

const Video = ({ video, height, width }) => {
  const {
    content: { videoUrl, desktop = null },
  } = video

  const [windowWidth, setWindowWidth] = useState(null)
  const [videoPath, setVideoPath] = useState(null)

  const spinnerStyles = {
    width: '24px',
    height: '24px',
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const url =
      windowWidth >= desktop?.breakpointWidth && desktop?.videoUrl
        ? desktop?.videoUrl
        : videoUrl
    setVideoPath(url)
  })

  return (
    <React.Fragment>
      <div sx={styles.modalOverlay} />
      <div sx={styles.modalWrapper}>
        {!videoPath ? (
          <Spinner sx={{ ...spinnerStyles }} data-cy="spinner-loading" />
        ) : (
          <ReactPlayer
            url={videoPath}
            height={height}
            width={width || '400'}
            autoPlay
            onContextMenu={(e) => e.preventDefault()}
            config={{
              file: {
                attributes: { controlsList: 'nodownload' },
                forceAudio: true,
                forceVideo: true,
              },
            }}
            muted={true}
            playing={true}
            controls={false}
            playsinline
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default Video
