/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FormBuilder } from '@guidesmiths/react-form-builder'
import { keyframes } from '@emotion/react'
import Box from '../Commons/Box'
import TextBlockRenderer from '../Commons/TextBlockRenderer'
import Header from '../Header'
import Footer from '../Footer'
import Poll from '../Poll'
import Text from '../Commons/Text'
import Video from '../Commons/Video'
import MediaPage from '../Commons/Video/mediaPage'
import Modal from '../Commons/Modal'
import SocialBlock from '../SocialBlock'
import ImageBlock from '../ImageBlock'
import ButtonBlock from '../ButtonBlock'
import MarkdownContent from '../Commons/ModalMarkdownContent'
import { useModalDispatch, useModalState } from '../../contexts/modal'
import IframeContainer from '../Commons/IframeContainer'
import { useEffect, useState } from 'react'

const defaultFormConfig = {
  callForAction: [
    {
      caption: 'Enter',
      type: 'submit',
    },
  ],
}
const fadeOut = keyframes({ from: { opacity: 1 }, to: { opacity: 0 } })
const animationDuration = 2
const videoDuration = 11
const fadeDelay = (videoDuration + 1) * 1000
const animationDelay = videoDuration - 0.45
const animationDelayDesktop = videoDuration - 1.5

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  videoContainer: {
    // media query for Safari and IOS
    '@media (-webkit-animation)': {
      animation: `${fadeOut} ${animationDuration}s ${animationDelay}s forwards`,
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    animation: `${fadeOut} ${animationDuration}s ${animationDelayDesktop}s forwards`,
    zIndex: '1',
  },
}

const MediaTemplate = ({ page, sendForm, hasFormError }) => {
  const modalState = useModalState()
  const modalDispatch = useModalDispatch()
  const [isMediaPlaying, setMediaPlaying] = useState(true)
  const [isFormDisplayed, setFormDisplayed] = useState(false)

  const displayForm = () => {
    setTimeout(() => {
      setFormDisplayed(true)
    }, (videoDuration - 1.5) * 1000)
  }

  useEffect(() => {
    if (page.slug === 'landing') {
      displayForm()
    } else {
      setFormDisplayed(true)
    }

    setTimeout(() => {
      setMediaPlaying(false)
    }, fadeDelay)
  })

  const onLinkOpen = (content) => {
    if ('parentIFrame' in window) {
      // eslint-disable-next-line no-undef
      window.parentIFrame.scrollTo(0, 0)
    }
    modalDispatch({
      type: 'show',
      payload: <MarkdownContent content={content} />,
    })
  }

  const layoutTypes = {
    form: (layoutElement, i) => {
      return (
        <Box sx={{ display: !isFormDisplayed ? 'none' : 'auto' }}>
          <FormBuilder
            key={i}
            {...{
              idForm: defaultFormConfig.id,
              form: layoutElement.content,
              language: layoutElement?.content?.language,
              onSubmit: sendForm,
              onLinkOpen: (name) =>
                onLinkOpen(
                  layoutElement?.content?.modal[name].replace(/\\n/g, '\n'),
                ),
            }}
          />
        </Box>
      )
    },
    text: (layoutElement, i) => (
      <TextBlockRenderer
        sx={{
          variant: 'box.textBlock.' + layoutElement?.name,
          display: !isFormDisplayed ? 'none' : 'auto',
        }}
        key={i}
        textBlock={layoutElement.content.text}
      />
    ),

    image: (layoutElement, i) => (
      <ImageBlock
        key={i}
        image={layoutElement}
        display={!isFormDisplayed ? 'none' : 'auto'}
      />
    ),
    poll: (layoutElement, i) => (
      <Poll
        key={i}
        poll={layoutElement}
        display={!isFormDisplayed ? 'none' : 'auto'}
      />
    ),
    media: (layoutElement, i) => (
      <Box sx={isMediaPlaying ? styles.videoContainer : { display: 'none' }}>
        <MediaPage video={layoutElement} key={i} width="100vw" height="auto" />
      </Box>
    ),
    video: (layoutElement, i) => (
      <Video
        key={i}
        video={layoutElement}
        display={!isFormDisplayed ? 'none' : 'auto'}
      />
    ),
    social: (layoutElement, i) => (
      <SocialBlock key={i} social={layoutElement} />
    ),
    button: (layoutElement, i) => (
      <ButtonBlock key={i} button={layoutElement} />
    ),
  }

  return (
    <div sx={{ margin: '0 auto' }}>
      {page?.header && <Header content={page?.header} />}
      <Box sx={styles.container}>
        <main sx={styles.container}>
          {page?.layout ? (
            page?.layout.map((layoutElement, i) => {
              return layoutTypes[layoutElement.type](layoutElement, i)
            })
          ) : (
            <Text textStyle="title">No info available</Text>
          )}
        </main>
        {hasFormError && <p>Something went wrong submitting the form :( </p>}
      </Box>
      <Modal
        show={modalState !== null}
        onClose={() => modalDispatch({ type: 'hide' })}
      >
        {modalState}
      </Modal>
      {page?.footer && <Footer content={page?.footer} />}
      <IframeContainer />
    </div>
  )
}

export default MediaTemplate
