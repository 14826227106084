import Text from '../Commons/Text'
import Box from '../Commons/Box'
import React from 'react'

const BrowserNotSupportedMessage = () => (
  <Box
    sx={{
      minWidth: '50vw',
      minHeight: 'calc(100vh - 79px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    }}
  >
    <Text sx={{ fontSize: '20px' }}>
      This browser does not support all the features. For a safer, faster and
      complete experience, please use another browser.
    </Text>
  </Box>
)

export default BrowserNotSupportedMessage
